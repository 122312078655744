/**  =====================
  file-manager css start
==========================  **/
.file-manger-wrapper {
  .card {
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
    }
  }
}
.file-card {
  position: relative;
  .form-check {
    .form-check-label {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--bs-border-radius);
      }
    }
    .form-check-input {
      &:checked {
        + .form-check-label {
          &::after {
            background: rgba(var(--bs-primary-rgb), 0.03);
            box-shadow:
              0px 8px 24px rgba(27, 46, 94, 0.12),
              inset 0 0 0 2px var(--bs-primary);
          }
        }
      }
    }
  }
  .user-popup {
    position: relative;
    z-index: 5;
  }
  .user-group {
    .avtar {
      width: 30px;
      height: 30px;
    }
  }
  &.table {
    border-collapse: separate;
    width: calc(100% - 20px);
    margin: 0 10px;
    border-spacing: 0 10px;
    tr {
      background: var(--bs-card-bg);
      border: 1px solid var(--bs-border-color);
      border-radius: var(--bs-border-radius);
      box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
      td {
        &:nth-child(2) {
          cursor: pointer;
        }
      }
    }
    ul {
      margin-bottom: 0;
    }
  }
}

.nav-files {
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  padding: 10px;
  background: var(--bs-card-bg);

  &.nav-pills {
    .nav-link {
      padding: 0;
      color: var(--bs-body-color);
      font-size: 20px;
      width: 40px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
    }

    .nav-link.active,
    .show > .nav-link {
      color: var(--bs-body-color);
      background-color: var(--bs-card-bg);
      box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
    }
  }
}

// file-manager css end
