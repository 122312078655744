/**  =====================
  36. Customizer css start
==========================  **/
// main framework
.pct-c-btn {
  display: block;
  padding: 0px;
  border: 4px solid $card-bg;
  border-right: none;
  position: fixed;
  overflow: hidden;
  right: -4px;
  top: 100px;
  z-index: 1030;
  transition: all 0.15s ease-in-out;
  border-radius: 50% 4px 4px 50%;
  box-shadow: -6px 0px 14px 1px rgba(27, 46, 94, 0.04);
  background: $card-bg;

  a {
    display: block;
    padding: 12px 16px;
    transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);

    i {
      font-size: 24px;
      display: block;
      animation: anim-rotate 2.5s infinite linear;
      line-height: 24px;
      color: var(--bs-primary);
    }

    &:hover {
      background-color: rgba(var(--bs-primary-rgb), 0.1);
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

@keyframes anim-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pct-offcanvas {
  --bs-offcanvas-width: 320px;
  --bs-offcanvas-zindex: 1079;

  transition: transform 0.45s cubic-bezier(0.37, 0.24, 0.53, 0.99);
  box-shadow: 8px 0 14px rgba(27, 46, 94, 0.4);

  ~ .offcanvas-backdrop {
    opacity: 0.2;
  }

  .offcanvas-body {
    padding: 25px;
  }

  .avtar.avtar-xs {
    width: 36px;
    height: 36px;
  }

  .list-group-item {
    padding: 20px 0;

    > div > .pct-content,
    a.btn {
      padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    }
  }
}

.preset-btn {
  height: 60px;
  border: 2px solid var(--bs-gray-300);
  --bs-btn-active-border-color: var(--bs-gray-300);

  &:hover {
    border-color: var(--bs-primary);
  }

  &:active,
  &.active {
    border-color: var(--bs-primary);
  }
  &.btn-img {
    height: auto;
    border-radius: 8px;
    img {
      width: 100%;
    }
  }
}

.theme-color {
  display: flex;
  position: relative;
  padding: 0;
  > a {
    position: relative;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
    margin-right: 4px;
    width: 20px;
    height: 50px;
    flex: none;

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.3);
      z-index: 1;
      transform: scale(0);
      transition: all 0.15s ease-in-out;
    }
    i {
      font-size: 26px;
      color: #fff;
      transform: scale(0);
      transition: all 0.15s ease-in-out;
      &::before {
        position: relative;
        z-index: 5;
      }
    }
    &:hover {
      &::after {
        transform: scale(1);
      }
    }
    &.active {
      flex: 1;
      &::after,
      i {
        transform: scale(1);
      }
    }
  }
  &.preset-color {
    $i: 1;
    @each $name, $value in $preset-colors {
      > a {
        &[data-value='preset-#{$i}'] {
          background: map-get($value, 'primary');
        }
      }
      $i: $i + 1;
    }
  }
}

.theme-layout {
  .btn {
    i {
      font-size: 26px;
    }
  }
}

.theme-main-layout {
  a {
    padding: 5px;
    border: 2px solid var(--bs-gray-300);
    border-radius: 8px;
    &.active {
      border: 2px solid var(--bs-primary);
    }
  }
}

.customizer-body {
  height: calc(100% - 85px);
}

.preset-btn {
  padding: 5px;
  width: 100%;
  line-height: 1;
  &.active {
    border-color: var(--bs-primary);
  }
}

.pc-box-width {
  @include media-breakpoint-down(xl) {
    display: none;
  }
}
[data-pc-layout='compact'] [data-pc-layout='horizontal'] {
  .pc-rtl {
    display: none;
  }
}

.auth-main {
  ~ .pct-offcanvas {
    .pc-boxcontainer {
      display: none;
    }
  }
}

/**  =====================
  36. Customizer css end
==========================  **/
